import React from "react";
import './footer.css'

function Footer() {
  return (
    <footer class="footer-container text-center">
      <div>Established 2023 | <a href="#">pnkeys.com</a></div>
    </footer>
  );
}

export default Footer;
